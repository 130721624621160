#audit {
    .section-inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        @media all and (min-width: 1144px) {
            flex-direction: row;
            align-items: initial;

            > div {
                max-width: calc(50% - 10px);
            }
        }
    }

    .column-locked {
        margin-bottom: 100px;

        @media all and (min-width: 1144px) {
            margin-bottom: initial;
        }
    }
}

.gaming {
    .gaming-android-apple {
        display: flex;
        margin: 55px auto;
        gap: 50px;
        padding: 0 40px;

        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: calc(50% - 15px);
        }

        strong {
            font-size: 24px;
        }

        img {
            max-width: 150px;

            @media all and (min-width: 1144px) {
                max-width: 250px;
            }
        }
    }

    .gaming-gallery {
        display: flex;
        gap: 8px;
        justify-content: center;

        img {
            height: 420px;
            width: 200px;
            object-fit: cover;
            object-position: bottom;
        }
    }
}

.nft-preview {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 25px;
    margin-bottom: 35px;

    img {
        width: auto;
        height: 200px;
        border: 5px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(to top,rgb(255,0,122),#F17741);

        @media all and (min-width: 875px) {
            height: 400px;
        }
    }
}