.buy-info {
   background-color: #0072017a;
   border: 2px solid #007201;
   padding: 5px 15px;
   color: #ffffff;
   border-radius: 5px;
   margin-top: 20px;

   a {
     text-decoration: underline;
     color: #ffffff;
   }

   br {
       @media all and (min-width: 1000px) {
           display: none;
       }
   }
}

.method-swap {
  margin-bottom: 50px;

  button {
    transition: background-color .2s ease-in-out, color .2s ease-in-out;
    cursor: pointer;

    &:first-of-type {
      margin-right: 20px;
    }

    &.active,
    &:hover {
      background-color: #F17742;
      color: #ffffff;
    }
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;

    button {
      &:first-of-type {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}

.buyPoodlSwap {
  #swapzone,
  #guardarian {
    display: none;

    &.active {
      display: block;
    }
  }
}

#guardarian {
  > iframe {
    border: none;
    width: auto;
    height: 382px;
    padding: 20px;
    background-color: #101010;
    border-radius: 8px;
    margin: 0 auto;
    box-sizing: border-box;
  }
}